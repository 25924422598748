import type { FC } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import React, {
	Fragment,
	useContext,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import type { DataProxy } from 'apollo-cache';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import type { EditorActions } from '@atlaskit/editor-core';
import type { AnnotationInfo } from '@atlaskit/editor-plugins/annotation';
import { AnnotationUpdateEvent } from '@atlaskit/editor-common/types';
import { AnnotationMarkStates, AnnotationTypes } from '@atlaskit/adf-schema';
import type { AddMarkStep, Step } from '@atlaskit/editor-prosemirror/transform';
import type { JSONDocNode } from '@atlaskit/editor-json-transformer';
import { ChromelessEditor } from '@atlaskit/editor-core/appearance-editor-chromeless';

import { ThirdPartyNudge } from '@confluence/third-party-nudge';
import type {
	InlineCommentReply,
	NewReplyVars,
	ContentRepresentation,
	InlineCommentQueryReply,
	CreateInlineReplyMutationType,
	InlineCommentQueryType,
	InlineCommentQueryVariables,
	GraphQLContentStatus,
	InlineCommentAuthorUser,
	InlineCommentLocation,
	PageInfoQueryType,
} from '@confluence/inline-comments-queries';
import { useCommentSidebarOffset } from '@confluence/inline-comments-hooks';
import { useSessionData, useBooleanFeatureFlag } from '@confluence/session-data';
import { useGetPageMode } from '@confluence/page-utils/entry-points/useGetPageMode';
import { useUnreadCommentsForInlineComment } from '@confluence/unread-comments';
import { ErrorDisplay } from '@confluence/error-boundary';
import {
	InlineCommentMode,
	InlineCommentFramework,
} from '@confluence/inline-comments-common/entry-points/enum';
import {
	getEditorAnnotationEventEmitter,
	getRendererAnnotationEventEmitter,
} from '@confluence/annotation-event-emitter';
import { PageSegmentLoadEnd } from '@confluence/browser-metrics';
import {
	VIEW_INLINE_COMMENT_EXPERIENCE,
	RESOLVE_INLINE_COMMENT_EXPERIENCE,
	REPLY_TO_INLINE_COMMENT_EXPERIENCE,
	REPLY_TO_INLINE_COMMENT_LOAD_EXPERIENCE,
	ExperienceTrackerContext,
	EDIT_INLINE_COMMENT_EXPERIENCE,
	DELETE_INLINE_COMMENT_EXPERIENCE,
} from '@confluence/experience-tracker';
import { usePageContentId } from '@confluence/page-context';
import { useCommentContentDispatchContext } from '@confluence/comment-context';
import { CommentEditor, clearCommentDraft, useInlineCommentQueryParams } from '@confluence/comment';
import { markErrorAsHandled } from '@confluence/graphql';
import {
	CommentDeletionLocation,
	CreateInlineReplyMutation,
	ResolveInlineCommentMutation,
	DeleteInlineCommentMutation,
	DeleteInlineCommentMutationWithStep,
	InlineCommentQuery,
	PageInfoQuery,
	CommentCreationLocation,
} from '@confluence/inline-comments-queries';
import {
	CollapsedReplies,
	CommentAuthor,
	CommentBody,
	ResolvedCommentNotification,
	ReopenedMessage,
} from '@confluence/inline-comments-common';
import { useAddCommentPermissionCheck } from '@confluence/comments-hooks';
import {
	parseError,
	getTranslatedError,
} from '@confluence/inline-comments-common/entry-points/inlineCommentsUtils';
import {
	ReplyListContainer,
	NewReplyContainer,
} from '@confluence/inline-comments-common/entry-points/styled';
import type {
	CommentPermissions,
	CommentAction,
} from '@confluence/inline-comments-common/entry-points/inlineCommentsTypes';
import { constructStepForGql, handleScrollToElement } from '@confluence/comments-util';
import { useInlineComments } from '@confluence/inline-comments-hooks/entry-points/useInlineComments';
import { END } from '@confluence/navdex';
import { useIsLivePage } from '@confluence/live-pages-utils/entry-points/useIsLivePage';
import { getLogger } from '@confluence/logger';
import {
	useShouldDisplayCommentReplyPrompts,
	useSuggestedComments,
} from '@confluence/suggested-comment-prompts';

import { getTargetNodeType } from './analyticsUtils';
import {
	EDITOR_INLINE_COMMENT_RENDER_METRIC,
	RENDERER_INLINE_COMMENT_RENDER_METRIC,
} from './perf.config';
import { MAX_UNCOLLAPSED_REPIES } from './inlineCommentConstants';
import { type CommentNavigationOptions, CommentSidebar } from './CommentSidebar';
import { EditComment } from './EditComment';

const logger = getLogger('annotation-provider-inline-comment');
type ActionResult = { step: Step; doc: JSONDocNode } | false;

type DeleteOptions = {
	deleteAnnotation: (annotationInfo: AnnotationInfo) => ActionResult;
	onDeleteSuccess: (doc: JSONDocNode, annotationId: string) => void;
};

type InlineCommentProps = {
	editorActions?: EditorActions;
	isEditor?: boolean;
	isArchived?: boolean;
	onNavigationClick: (nextMarkerRef: string) => void;
	annotation: AnnotationInfo;
	/**
	 * Return a list of inline node types, which are wrapped by the annotation,
	 * for annotation with given ID.
	 *
	 * The `undefined` will be returned if `editor_inline_comments_on_inline_nodes` is off.
	 *
	 * @todo: Do not forget to remove `undefined` when the
	 *        `editor_inline_comments_on_inline_nodes` is removed.
	 */
	getInlineNodeTypes: (annotationId: string) => string[] | undefined;
	onResolve: (annotationId: string) => void;
	deleteOptions?: DeleteOptions;
	onClose?: () => void;
	onDelete?: (annotationId: string) => void;
	supportedTopLevelActions?: CommentAction[];
};

type ResolvedComment = {
	commentId: string;
	inlineMarkerRef: string;
};

type MutationResult = {
	data?: CreateInlineReplyMutationType | Boolean | null;
};

export const InlineComment: FC<InlineCommentProps> = ({
	annotation: selectedAnnotation,
	onResolve,
	deleteOptions,
	onClose,
	isEditor,
	isArchived,
	onNavigationClick,
	onDelete,
	supportedTopLevelActions,
	getInlineNodeTypes,
}) => {
	const pageMode = useGetPageMode();
	const annotationElementRef = useRef<HTMLElement | null>(null);
	const resolveWindowCloseTimeout = useRef<ReturnType<typeof setTimeout> | undefined>();
	const [eventEmitter] = useState(() =>
		isEditor ? getEditorAnnotationEventEmitter() : getRendererAnnotationEventEmitter(),
	);
	const [shouldFocusNewReply, setShouldFocusNewReply] = useState(false);
	const experienceTracker = useContext(ExperienceTrackerContext);
	const [contentId] = usePageContentId();
	// @ts-ignore FIXME: `contentId` can be `undefined` here, and needs proper handling
	const pageId: string = contentId;
	const [{}, { addUnresolvedInlineComment, removeUnresolvedInlineComment }] = useInlineComments();
	const isLivePage = useIsLivePage();
	const { canAddComments } = useAddCommentPermissionCheck(pageId);

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { userId: currentUserId } = useSessionData();
	const {
		focusedCommentId,
		replyToCommentId,
		editCommentId: editCommentQueryId,
	} = useInlineCommentQueryParams();

	const [editCommentId, setCommentForEdit] = useState('');
	const [resolvedComment, setResolvedComment] = useState<ResolvedComment | null>(null);
	const [shouldRender, setShouldRender] = useState(false);

	const isThirdPartyNudgeEnabled = useBooleanFeatureFlag(
		'confluence.frontend.third-party-nudge_fl7ws',
	);

	const [isReplyChainCollapsed, setIsReplyChainCollapsed] = useState(true);
	const { onChange, resetContentChanged } = useCommentContentDispatchContext();

	const sidebarOffset = useCommentSidebarOffset({
		isEditor: isEditor || false,
		annotationElement: document.getElementById(selectedAnnotation.id),
		isViewCommentMode: true,
	});

	const RENDER_METRIC = isEditor
		? EDITOR_INLINE_COMMENT_RENDER_METRIC
		: RENDERER_INLINE_COMMENT_RENDER_METRIC;

	const handleGqlError = (error: Error) => {
		experienceTracker.stopOnError({
			name: VIEW_INLINE_COMMENT_EXPERIENCE,
			error,
			attributes: { isUnreadCommentsEnabled },
		});
	};

	const { data, loading, refetch } = useQuery<InlineCommentQueryType>(InlineCommentQuery, {
		variables: {
			pageId,
			annotationId: selectedAnnotation?.id,
			contentStatus: [isArchived ? 'ARCHIVED' : 'DRAFT', 'CURRENT'] as GraphQLContentStatus[],
		},
		onError: handleGqlError,
		fetchPolicy: 'cache-and-network',
	});

	const { data: pageInfoData, loading: pageInfoLoading } = useQuery<PageInfoQueryType>(
		PageInfoQuery,
		{
			variables: {
				pageId,
			},
			onError: handleGqlError,
		},
	);

	const getInlineCommentMode = useCallback(() => {
		if (isEditor) {
			if (isLivePage) {
				return InlineCommentMode.LIVE;
			}
			return InlineCommentMode.EDIT;
		}

		return InlineCommentMode.VIEW;
	}, [isEditor, isLivePage]);

	// When the selectedAnnotation changes make sure we unset whatever resolved logic exists
	useEffect(() => {
		setResolvedComment(null);
		clearTimeout(resolveWindowCloseTimeout.current);
		resolveWindowCloseTimeout.current = undefined;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedAnnotation]);

	const [resolveInlineCommentFn] = useMutation(ResolveInlineCommentMutation);
	const [deleteInlineCommentFn] = useMutation(DeleteInlineCommentMutation);
	const [deleteInlineCommentWithStepFn] = useMutation(DeleteInlineCommentMutationWithStep);
	const [createReplyFn] = useMutation<
		{ replyInlineComment: InlineCommentReply },
		{ input: NewReplyVars }
	>(CreateInlineReplyMutation);

	const pageInfo = pageInfoData?.content?.nodes?.[0];

	// Editor setup
	const spaceId = pageInfo?.space?.id ?? '';
	const pageType = pageInfo?.type ?? '';

	// User page permissions
	const operations = pageInfo?.operations || [];

	// The user can upload media only if they have update permissions for the page
	const hasMediaUploadPermissions = operations.some(
		(op) => op?.operation === 'update' && op?.targetType === pageType,
	);

	// Comment metadata
	const commentData = data?.comments?.nodes?.[0];
	const topCommentUserId = (commentData?.author as InlineCommentAuthorUser)?.accountId;
	const topCommentUserAvatar = commentData?.author?.profilePicture?.path;
	const topCommentDisplayName = commentData?.author?.displayName ?? 'Anonymous';
	const commentId = commentData?.id;
	const when = commentData?.version?.when;
	const permissions = commentData?.permissions;
	const content = commentData?.body?.value;
	const permissionType = commentData?.author?.permissionType;
	const numReplies = commentData?.replies?.length || 0;
	const commentThreadLength = 1 + numReplies; // includes root comment
	const resolvedTime = (commentData?.location as InlineCommentLocation)?.inlineResolveProperties
		?.resolvedTime;
	const resolvedUser = (commentData?.location as InlineCommentLocation)?.inlineResolveProperties
		?.resolvedUser;
	const commentUrl = isEditor && !isLivePage ? commentData?.links.editui : commentData?.links.webui;

	const shouldCollapseReplies =
		isReplyChainCollapsed && numReplies && numReplies > MAX_UNCOLLAPSED_REPIES;

	const { isUnreadCommentsEnabled, unreadCommentRefs, currentUnreadIds, markCommentReadError } =
		useUnreadCommentsForInlineComment({
			commentData,
			isEditor: Boolean(isEditor),
			focusedCommentId: focusedCommentId ?? null,
			shouldCollapseReplies: Boolean(shouldCollapseReplies),
			setIsReplyChainCollapsed,
		});

	const [, { updateSuggestedCommentIDMapLength }] = useSuggestedComments();
	const shouldDisplayCommentReplyPrompts = useShouldDisplayCommentReplyPrompts({
		contentId: pageId,
		commentData,
		userId: currentUserId,
	});

	useEffect(() => {
		RENDER_METRIC.start();

		experienceTracker.start({
			name: VIEW_INLINE_COMMENT_EXPERIENCE,
			id: selectedAnnotation.id,
			attributes: {
				mode: getInlineCommentMode(),
				framework: InlineCommentFramework.ANNOTATION_PROVIDER,
			},
		});
	}, [RENDER_METRIC, selectedAnnotation, experienceTracker, isEditor, getInlineCommentMode]);

	useEffect(() => {
		if (commentId) {
			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					action: 'viewed',
					actionSubject: 'inlineCommentsSidebar',
					objectType: 'page',
					objectId: pageId,
					source: isEditor ? 'editPageScreen' : 'viewPageScreen',
					attributes: {
						mode: getInlineCommentMode(),
						framework: InlineCommentFramework.ANNOTATION_PROVIDER,
						commentsDeckSize: numReplies + 1, // +1 for the parent comment
						commentId,
					},
				},
			}).fire();

			experienceTracker.succeed({
				name: VIEW_INLINE_COMMENT_EXPERIENCE,
				attributes: { isUnreadCommentsEnabled },
			});

			updateSuggestedCommentIDMapLength(commentId, commentThreadLength);
		}
		// We really only need to depend on these two things and want this to fire when ONLY they change
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageId, commentId]);

	useEffect(() => {
		if (editCommentQueryId) {
			const isReplyToCommentInChain = commentData?.replies
				?.map((reply) => reply?.id)
				?.includes(editCommentQueryId);
			if (isReplyToCommentInChain) {
				setIsReplyChainCollapsed(false);
			}
			setCommentForEdit(editCommentQueryId);
		}
	}, [editCommentQueryId, commentData]);

	useEffect(() => {
		const isReplyToCommentInChain = commentData?.replies
			?.map((reply) => reply?.id)
			?.includes(replyToCommentId);
		setShouldFocusNewReply(
			Boolean(replyToCommentId) && (isReplyToCommentInChain || replyToCommentId === commentId),
		);
	}, [replyToCommentId, commentId, commentData]);

	useEffect(() => {
		if (focusedCommentId) {
			const isReplyToCommentInChain = commentData?.replies
				?.map((reply) => reply?.id)
				?.includes(focusedCommentId);
			if (isReplyToCommentInChain) {
				setIsReplyChainCollapsed(false);
			}
		}
	}, [focusedCommentId, commentData]);

	useEffect(() => {
		const resolved = (commentData?.location as InlineCommentLocation)?.inlineResolveProperties
			?.resolved;

		if (resolved && !isEditor) {
			const commentId = commentData?.id;
			const markerRef = (commentData?.location as InlineCommentLocation)?.inlineMarkerRef;

			if (commentId && markerRef) {
				setResolvedComment({
					commentId,
					inlineMarkerRef: markerRef,
				});

				eventEmitter.emit(AnnotationUpdateEvent.SET_ANNOTATION_STATE, {
					[markerRef]: {
						id: markerRef,
						annotationType: AnnotationTypes.INLINE_COMMENT,
						state: AnnotationMarkStates.RESOLVED,
					},
				});
			}
		}
	}, [commentData, eventEmitter, isEditor]);

	useEffect(() => {
		if (loading === false && pageInfoLoading === false) {
			setShouldRender(true);
		}

		return () => {
			setShouldRender(false);
		};
	}, [loading, pageInfoLoading, pageId]);

	const toggleCommentMode = (id = '') => {
		setCommentForEdit(id);
	};

	const updateApolloCacheForReply = useCallback(
		(actionType: 'delete' | 'create', commentId?: string) =>
			(cache: DataProxy, result: MutationResult) => {
				// If the mutation fails, don't do anything
				if (!result) {
					return;
				}

				const queryVariables = {
					pageId,
					annotationId: selectedAnnotation?.id,
					contentStatus: ['DRAFT', 'CURRENT'] as GraphQLContentStatus[],
					isUnreadCommentsEnabled,
				};

				try {
					const response = cache.readQuery<InlineCommentQueryType, InlineCommentQueryVariables>({
						query: InlineCommentQuery,
						variables: queryVariables,
					});

					if (!response) {
						return;
					}

					// grab the parent comment
					const parentComment = response?.comments?.nodes?.[0];
					let duplicatedReplies: (InlineCommentQueryReply | null)[] | null = null;

					if (parentComment) {
						if (actionType === 'delete') {
							duplicatedReplies = [...parentComment.replies];

							// Remove the returned commentId from the cache and reset it
							const idxToRemove = duplicatedReplies.findIndex((reply) => reply?.id === commentId);

							if (idxToRemove !== -1) {
								duplicatedReplies.splice(idxToRemove, 1);
							}
						} else {
							const mutationResult = (result.data as CreateInlineReplyMutationType)
								.replyInlineComment;

							// Add the new reply entry to the cache and reset it
							duplicatedReplies = [...parentComment.replies];
							duplicatedReplies.push(mutationResult);
						}

						if (duplicatedReplies) {
							const updatedResponse = {
								...response,
								comments: {
									...response.comments,
									nodes: [
										{
											...parentComment,
											replies: duplicatedReplies,
										},
									],
								},
							};

							cache.writeQuery({
								query: InlineCommentQuery,
								variables: queryVariables,
								data: updatedResponse,
							});
						}
					}
				} catch (err) {
					logger.error`An Error occurred when updating cache for ${actionType} reply - ${err}`;
				}
			},
		[selectedAnnotation, pageId, isUnreadCommentsEnabled],
	);

	const handleDeleteCommentInRenderer = useCallback(
		(isReply: boolean, replyCommentId?: string) => {
			// TODO: refactor so deleteResult isn't called on reply
			// Call onDelete(selectedAnnotation) to get step and any other required variables
			const deleteResult = deleteOptions?.deleteAnnotation(selectedAnnotation);
			if (deleteResult) {
				// top-level comment calls `deleteInlineCommentWithStepFn`
				// reply calls `deleteInlineComment`
				const mtnVariables = {
					variables: isReply
						? {
								commentIdToDelete: replyCommentId,
								deleteFrom: CommentDeletionLocation.LIVE,
							}
						: {
								input: {
									commentId,
									step: constructStepForGql(deleteResult.step as AddMarkStep),
								},
							},
				};

				// After deleting it will not be available, so we need to get the inline node types before deleting.
				const inlineNodeTypes = selectedAnnotation
					? getInlineNodeTypes(selectedAnnotation.id)
					: undefined;

				const deleteFn = isReply ? deleteInlineCommentFn : deleteInlineCommentWithStepFn;

				deleteFn({
					...mtnVariables,
					update: isReply ? updateApolloCacheForReply('delete', replyCommentId) : undefined,
				})
					.then(({ data }) => {
						if (!data || (!isReply && !data.deleteInlineComment)) {
							throw new Error('No data returned from mutation');
						}

						const targetNodeType = getTargetNodeType(selectedAnnotation.id, false);

						if (!isReply) {
							deleteOptions?.onDeleteSuccess(deleteResult.doc, selectedAnnotation?.id);

							removeUnresolvedInlineComment(selectedAnnotation?.id);
						}

						createAnalyticsEvent({
							type: 'sendTrackEvent',
							data: {
								action: 'deleted',
								actionSubject: 'comment',
								actionSubjectId: commentId,
								objectType: 'page',
								objectId: pageId,
								source: 'viewPageScreen',
								attributes: {
									commentType: 'inline',
									mode: getInlineCommentMode(),
									framework: InlineCommentFramework.ANNOTATION_PROVIDER,
									inlineNodeTypes,
									isReply,
									targetNodeType,
								},
							},
						}).fire();

						experienceTracker.succeed({
							name: DELETE_INLINE_COMMENT_EXPERIENCE,
						});

						if (!isReply) {
							// Reset the content state on successful deletion on a non-reply
							resetContentChanged();
							onClose && onClose();
						}
					})
					.catch((err) => {
						experienceTracker.stopOnError({
							name: DELETE_INLINE_COMMENT_EXPERIENCE,
							error: err,
						});
					});
			}
		},
		[
			commentId,
			createAnalyticsEvent,
			deleteInlineCommentFn,
			deleteInlineCommentWithStepFn,
			deleteOptions,
			experienceTracker,
			getInlineCommentMode,
			onClose,
			pageId,
			removeUnresolvedInlineComment,
			selectedAnnotation,
			updateApolloCacheForReply,
			getInlineNodeTypes,
			resetContentChanged,
		],
	);

	const handleDeleteCommentInEditorAndLivePages = useCallback(
		(isReply: boolean, replyCommentId?: string) => {
			const targetNodeType = getTargetNodeType(selectedAnnotation.id, true);
			// both top-level comments and replies call `deleteInlineComment`
			deleteInlineCommentFn({
				variables: {
					commentIdToDelete: isReply ? replyCommentId : commentId,
					deleteFrom: isLivePage ? CommentDeletionLocation.LIVE : CommentDeletionLocation.EDITOR,
				},
				update: isReply ? updateApolloCacheForReply('delete', replyCommentId) : undefined,
			})
				.then(({ data }) => {
					if (!data || (!isReply && !data.deleteComment)) {
						throw new Error('No data returned from mutation');
					}

					createAnalyticsEvent({
						type: 'sendTrackEvent',
						data: {
							action: 'deleted',
							actionSubject: 'comment',
							actionSubjectId: commentId,
							objectType: 'page',
							objectId: pageId,
							source: 'editPageScreen',
							attributes: {
								commentType: 'inline',
								mode: getInlineCommentMode(),
								framework: InlineCommentFramework.ANNOTATION_PROVIDER,
								inlineNodeTypes: selectedAnnotation
									? getInlineNodeTypes(selectedAnnotation.id)
									: undefined,
								isReply,
								targetNodeType,
							},
						},
					}).fire();

					experienceTracker.succeed({
						name: DELETE_INLINE_COMMENT_EXPERIENCE,
					});

					if (!isReply) {
						onDelete?.(selectedAnnotation?.id);
						// Reset the content state on successful deletion on a non-reply
						resetContentChanged();
					}
				})
				.catch((err) => {
					experienceTracker.stopOnError({
						name: DELETE_INLINE_COMMENT_EXPERIENCE,
						error: err,
					});
				});
		},
		[
			commentId,
			createAnalyticsEvent,
			deleteInlineCommentFn,
			experienceTracker,
			getInlineCommentMode,
			pageId,
			updateApolloCacheForReply,
			onDelete,
			selectedAnnotation,
			getInlineNodeTypes,
			resetContentChanged,
			isLivePage,
		],
	);

	const handleDeleteComment = useCallback(
		(isReply = false, replyCommentId?: string) => {
			if (!isEditor) {
				handleDeleteCommentInRenderer(isReply, replyCommentId);
			} else {
				handleDeleteCommentInEditorAndLivePages(isReply, replyCommentId);
			}
		},
		[isEditor, handleDeleteCommentInRenderer, handleDeleteCommentInEditorAndLivePages],
	);

	const handleResolveComment = () => {
		const mtnVariables = {
			variables: { commentId, resolved: true },
		};

		resolveInlineCommentFn(mtnVariables)
			.then(() => {
				const annotationId = selectedAnnotation.id;

				// this will be removed when the prop becomes optional
				onResolve(annotationId);

				// Update the unresolved inline comments count
				removeUnresolvedInlineComment(annotationId);

				createAnalyticsEvent({
					type: 'sendTrackEvent',
					data: {
						action: 'resolved',
						actionSubject: 'comment',
						actionSubjectId: commentId,
						objectType: 'page',
						objectId: pageId,
						source: 'editPageScreen',
						attributes: {
							commentType: 'inline',
							mode: getInlineCommentMode(),
							framework: InlineCommentFramework.ANNOTATION_PROVIDER,
							navdexPointType: END,
							inlineNodeTypes: selectedAnnotation
								? getInlineNodeTypes(selectedAnnotation.id)
								: undefined,
							targetNodeType: getTargetNodeType(annotationId, isEditor),
						},
					},
				}).fire();

				experienceTracker.succeed({
					name: RESOLVE_INLINE_COMMENT_EXPERIENCE,
				});

				// Resolve the comment
				if (isEditor) {
					eventEmitter.emit('resolve', selectedAnnotation.id);
				} else {
					// Show the resolved comment
					if (commentId) {
						setResolvedComment({
							commentId,
							inlineMarkerRef: annotationId,
						});
					}

					eventEmitter.emit(AnnotationUpdateEvent.SET_ANNOTATION_STATE, {
						[annotationId]: {
							id: annotationId,
							annotationType: AnnotationTypes.INLINE_COMMENT,
							state: AnnotationMarkStates.RESOLVED,
						},
					});

					// Set a timeout to auto-close the container
					resolveWindowCloseTimeout.current = setTimeout(() => {
						onClose && onClose();
					}, 7500);
				}
			})
			.catch((err) => {
				experienceTracker.stopOnError({
					name: RESOLVE_INLINE_COMMENT_EXPERIENCE,
					error: err,
				});
			});
	};

	const handleCreateReply = async (adf: object, onSuccess: () => void) => {
		const variables = {
			input: {
				containerId: pageId,
				parentCommentId: commentId ?? '',
				commentBody: {
					value: JSON.stringify(adf),
					representationFormat: 'ATLAS_DOC_FORMAT' as ContentRepresentation,
				},
				createdFrom: isLivePage
					? CommentCreationLocation.LIVE
					: isEditor
						? CommentCreationLocation.EDITOR
						: CommentCreationLocation.RENDERER,
			},
		};

		return createReplyFn({
			variables,
			update: updateApolloCacheForReply('create'),
		})
			.then(({ data }: any) => {
				// reset the editor
				onSuccess();

				if (editCommentQueryId) {
					setCommentForEdit('');
				}

				const replyCommentInfo = data?.replyInlineComment;

				const markerRef = replyCommentInfo?.location?.inlineMarkerRef;

				createAnalyticsEvent({
					type: 'sendTrackEvent',
					data: {
						action: 'created',
						actionSubject: 'comment',
						actionSubjectId: replyCommentInfo?.id, // The newly created comment ID
						objectType: pageType,
						objectId: pageId,
						source: isEditor ? 'editPageScreen' : 'viewPageScreen',
						attributes: {
							commentType: 'inline',
							parentCommentId: commentId ?? null, // analytics event schema type expects string or null
							mode: getInlineCommentMode(),
							framework: InlineCommentFramework.ANNOTATION_PROVIDER,
							navdexPointType: END,
							inlineNodeTypes: selectedAnnotation
								? getInlineNodeTypes(selectedAnnotation.id)
								: undefined,
							targetNodeType: getTargetNodeType(markerRef, isEditor),
						},
					},
				}).fire();

				experienceTracker.succeed({
					name: REPLY_TO_INLINE_COMMENT_EXPERIENCE,
				});
			})
			.catch((err) => {
				experienceTracker.stopOnError({
					name: REPLY_TO_INLINE_COMMENT_EXPERIENCE,
					error: err,
				});

				// get a truncated graphql error message
				const { message } = parseError(err);

				// we currently display a generic message to the user whenever an error occurs
				markErrorAsHandled(err);

				// CommentEditor expects a translated error message otherwise it fails
				// TODO: CommentEditor should likely just receive the translated message
				// rather than expect the object needed for FormattedMessage
				return Promise.reject({
					error: getTranslatedError(message, commentId),
				});
			});
	};

	const renderReplies = useCallback(
		(shouldCollapseReplies: boolean) => {
			const replies = data?.comments?.nodes?.[0]?.replies || [];
			const visibleReplies = shouldCollapseReplies ? replies.slice(-1) : replies;

			return visibleReplies.map((reply) => {
				if (!reply) {
					return <></>;
				}
				const { id, permissions, body } = reply;

				const matchingRef = unreadCommentRefs.find((refObj) => refObj.id === id)?.ref;
				if (id === editCommentId) {
					return (
						<EditComment
							key={id}
							commentId={id}
							annotationId={selectedAnnotation.id}
							displayName={reply.author?.displayName ?? 'Anonymous'}
							avatarUrl={reply.author?.profilePicture?.path ?? ''}
							pageId={pageId}
							pageType={pageType}
							spaceId={spaceId}
							content={body?.value}
							displayCommentInViewMode={toggleCommentMode}
							mode={isEditor ? 'edit' : 'view'}
							isReply
							hasMediaUploadPermissions={hasMediaUploadPermissions}
							getInlineNodeTypes={getInlineNodeTypes}
						/>
					);
				} else {
					return (
						<CommentBody
							key={id}
							userId={(reply.author as InlineCommentAuthorUser)?.accountId ?? ''}
							displayName={reply.author?.displayName}
							avatarUrl={reply.author?.profilePicture?.path}
							date={reply.version?.when ?? ''}
							dateUrl={
								isEditor && !isLivePage
									? reply.links.editui ?? undefined
									: reply.links.webui ?? undefined
							}
							commentId={id}
							pageId={pageId}
							pageType={pageType}
							isReply
							permissions={permissions}
							editComment={() => toggleCommentMode(id)}
							deleteComment={() => handleDeleteComment(true, id)}
							content={reply.body?.value}
							isCommentActive
							mode={isEditor ? 'edit' : 'view'}
							permissionType={reply.author?.permissionType ?? undefined}
							supportedActions={['edit', 'delete', 'resolve']}
							isFocused={focusedCommentId === id}
							isUnread={currentUnreadIds.has(id)}
							onRendered={() => {
								if (sidebarOffset > 0 && focusedCommentId) {
									handleScrollToElement({
										commentId: id,
										focusedCommentId,
										isEditor: Boolean(isEditor),
										useInstantScroll: true,
									});
								}
							}}
							unreadCommentRef={matchingRef}
						/>
					);
				}
			});
		},
		[
			data,
			editCommentId,
			pageType,
			pageId,
			spaceId,
			selectedAnnotation,
			isEditor,
			focusedCommentId,
			handleDeleteComment,
			isLivePage,
			sidebarOffset,
			currentUnreadIds,
			unreadCommentRefs,
			hasMediaUploadPermissions,
			getInlineNodeTypes,
		],
	);

	const handleOnClose = useCallback(() => {
		const isReply = editCommentId === '';

		experienceTracker.abort({
			name: isReply ? REPLY_TO_INLINE_COMMENT_EXPERIENCE : EDIT_INLINE_COMMENT_EXPERIENCE,
			reason: 'comment discarded by user',
		});

		// Clearing reply draft is handled in the comment editor
		clearCommentDraft(isEditor ? 'edit-inline' : 'inline', isReply ? 'reply' : 'edit');
		setIsReplyChainCollapsed(true);
		setShouldFocusNewReply(false);

		onClose && onClose();
	}, [experienceTracker, editCommentId, isEditor, onClose]);

	const handleReopen = useCallback(
		(annotationId: any) => {
			// Trigger a refetch to get the new details when we reopen the comment
			refetch()
				.then(() => {
					// Emit the event to re-highlight the mark
					eventEmitter.emit(AnnotationUpdateEvent.SET_ANNOTATION_STATE, {
						[annotationId]: {
							id: annotationId,
							annotationType: AnnotationTypes.INLINE_COMMENT,
							state: AnnotationMarkStates.ACTIVE,
						},
					});

					addUnresolvedInlineComment(annotationId, pageMode);

					// Show the comment again in the sidebar and clear the timeout
					setResolvedComment(null);
				})
				.catch((error) => {
					logger.error`Unable to refetch the details of the comment; ${error}`;
				});

			clearTimeout(resolveWindowCloseTimeout.current);
			resolveWindowCloseTimeout.current = undefined;
		},
		[eventEmitter, setResolvedComment, addUnresolvedInlineComment, refetch, pageMode],
	);

	const navigationOptions = useMemo(
		() => ({
			showNavigation: !resolvedComment,
			commentData,
			onNavigationClick,
		}),
		[resolvedComment, commentData, onNavigationClick],
	);

	if (!shouldRender) {
		return null;
	}

	const renderComment = () => {
		const matchingRef = unreadCommentRefs.find((refObj) => refObj.id === commentId)?.ref;
		if (commentId === editCommentId) {
			return (
				<EditComment
					pageId={pageId}
					pageType={pageType}
					annotationId={selectedAnnotation.id}
					commentId={commentId}
					spaceId={spaceId}
					content={content ?? ''}
					isReply={false}
					displayCommentInViewMode={toggleCommentMode}
					avatarUrl={topCommentUserAvatar ?? ''}
					displayName={topCommentDisplayName}
					mode={isEditor ? 'edit' : 'view'}
					hasMediaUploadPermissions={hasMediaUploadPermissions}
					getInlineNodeTypes={getInlineNodeTypes}
				/>
			);
		} else {
			return (
				<Fragment>
					<CommentBody
						userId={topCommentUserId}
						avatarUrl={topCommentUserAvatar}
						displayName={topCommentDisplayName}
						date={when ?? ''}
						dateUrl={commentUrl ?? ''}
						pageId={pageId}
						pageType={pageType}
						commentId={commentId ?? ''}
						isReply={false}
						permissions={permissions as CommentPermissions}
						deleteComment={!isLivePage && isEditor ? undefined : handleDeleteComment}
						editComment={() => toggleCommentMode(commentId)}
						content={content ?? ''}
						isCommentActive
						mode={isEditor ? 'edit' : 'view'}
						onClose={onClose}
						permissionType={permissionType ?? undefined}
						resolveComment={handleResolveComment}
						supportedActions={supportedTopLevelActions}
						isUnread={currentUnreadIds.has(commentId ?? '')}
						numReplies={numReplies}
						unreadCommentRef={matchingRef}
					/>
					<ReopenedMessage resolvedUser={resolvedUser ?? undefined} resolvedTime={resolvedTime} />
					<PageSegmentLoadEnd key={`stop-${commentId}`} metric={RENDER_METRIC} />
				</Fragment>
			);
		}
	};

	annotationElementRef.current = document.getElementById(selectedAnnotation.id);

	const isCommentAuthor = topCommentUserId === currentUserId;
	const isFirstComment = commentThreadLength === 1;

	return (
		<CommentSidebar
			pageId={pageId}
			annotationElement={annotationElementRef.current}
			navigationOptions={navigationOptions as CommentNavigationOptions}
			onClose={isEditor ? handleOnClose : onClose}
			isEditor={isEditor}
			isViewCommentMode
			sidebarOffset={sidebarOffset}
			commentId={commentId}
			scrollIntoView={
				// Scrolling is handled separately for focused comment and unread comments
				// hence we should opt out scroll into view
				!focusedCommentId && (!isUnreadCommentsEnabled || !currentUnreadIds.size)
			}
		>
			{markCommentReadError && <ErrorDisplay error={markCommentReadError} />}
			{resolvedComment ? (
				<ResolvedCommentNotification
					commentId={resolvedComment.commentId}
					reopenComment={handleReopen}
					pageId={pageId}
					inlineMarkerRef={resolvedComment.inlineMarkerRef}
					mode="view"
				/>
			) : (
				<Fragment>
					{renderComment()}
					{commentData?.replies && commentData?.replies.length > 0 && (
						<ReplyListContainer data-testid="inline-comment-reply-container">
							{shouldCollapseReplies && (
								<CollapsedReplies
									numCollapsedReplies={numReplies - 1}
									onClick={() => setIsReplyChainCollapsed(false)}
								/>
							)}
							{renderReplies(Boolean(shouldCollapseReplies))}
						</ReplyListContainer>
					)}
					{!isArchived && canAddComments && (
						<NewReplyContainer
							mode={isEditor ? 'edit' : 'view'}
							data-cy="editor-reply-container"
							data-testid="inline-comment-new-reply-container"
						>
							<CommentAuthor
								commentMode="reply"
								userId={currentUserId}
								size="small"
								permissionType={permissionType ?? undefined}
							/>
							<CommentEditor
								pageId={pageId}
								pageType={pageType}
								appearance="chromeless"
								EditorComponent={ChromelessEditor}
								onSaveComment={handleCreateReply}
								onContentChange={onChange}
								onEditorReady={() => {
									experienceTracker.succeed({
										name: REPLY_TO_INLINE_COMMENT_LOAD_EXPERIENCE,
									});
								}}
								commentMode="reply"
								commentType={isEditor ? 'edit-inline' : 'inline'}
								spaceId={spaceId}
								showCancelButton
								useNewWarningModal
								hideWatchCheckbox
								expandEditor={shouldFocusNewReply}
								pageMode={isEditor ? 'edit' : 'view'}
								hasMediaUploadPermissions={hasMediaUploadPermissions}
								shouldDisplayCommentReplyPrompts={shouldDisplayCommentReplyPrompts}
								topLevelCommentId={commentId}
								shouldWarnOnInternalNavigation
								commentThreadLength={commentThreadLength}
							/>
						</NewReplyContainer>
					)}
				</Fragment>
			)}
			{isThirdPartyNudgeEnabled && isCommentAuthor && isFirstComment && (
				<ThirdPartyNudge trigger="inline-comments" showImage={false} width="100%" />
			)}
		</CommentSidebar>
	);
};
